import ParticlesBg from 'particles-bg';
import React, { Component } from 'react';
import Fade from 'react-reveal';

class Header extends Component {
  render() {
    return (
      <header id='home'>
        <ParticlesBg type='circle' bg={true} />

        <nav id='nav-wrap'>
          <a className='mobile-btn' href='#nav-wrap' title='Show navigation'>
            Show navigation
          </a>
          <a className='mobile-btn' href='#home' title='Hide navigation'>
            Hide navigation
          </a>

          <ul id='nav' className='nav'>
            <li className='current'>
              <a className='smoothscroll' href='#home'>
                Home
              </a>
            </li>

            <li>
              <a className='smoothscroll' href='#about'>
                About
              </a>
            </li>

            <li>
              <a className='smoothscroll' href='#resume'>
                Resume
              </a>
            </li>

            <li>
              <a className='smoothscroll' href='#portfolio'>
                Works
              </a>
            </li>

            <li>
              <a className='smoothscroll' href='#footer'>
                Contact
              </a>
            </li>
          </ul>
        </nav>

        <div className='row banner'>
          <div className='banner-text'>
            <Fade bottom>
              <h1 className='responsive-headline'>Taha Aygün</h1>
            </Fade>
            <Fade bottom duration={1200}>
              <h3>
                Hi! I am a Full-Stack Developer based on Cologne area Germany. I am able to develop web and mobile applications with various
                tools and ship them to the production. If you are interested, feel free to check my portfolio below&nbsp;😼
              </h3>
            </Fade>
            <hr />
            <Fade bottom duration={2000}>
              <ul className='social'>
                <a href={'#portfolio'} className='button btn project-btn'>
                  Projects
                </a>
                <a href='https://github.com/tahaygun' target='_blank' className='button btn github-btn' rel='noreferrer'>
                  Github
                </a>
              </ul>
            </Fade>
          </div>
        </div>

        <p className='scrolldown'>
          <a className='smoothscroll' href='#about'>
            <i className='icon-down-circle'></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;

import React, { Component } from 'react';
import ReactGA from 'react-ga';
import './App.css';
import About from './Components/About';
import Footer from './Components/Footer';
import Header from './Components/Header';
import Portfolio from './Components/Portfolio';
import Resume from './Components/Resume';

class App extends Component {
  constructor(props) {
    super(props);

    ReactGA.initialize('UA-110570651-1');
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
      <div className='App'>
        <Header />
        <About />
        <Resume />
        <Portfolio />
        {/* <Contact /> */}
        <Footer />
      </div>
    );
  }
}

export default App;

import React, { Component } from 'react';
import Slide from 'react-reveal';

class Resume extends Component {
  getRandomColor() {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  getWork() {
    return (
      <Slide left duration={1300}>
        <div className='row work'>
          <div className='three columns header-col'>
            <h1>
              <span>Experience</span>
            </h1>
          </div>

          <div className='nine columns main-col'>
            <div>
              <h3>
                Shop-Apotheke
                <i>
                  <em> / Köln</em>
                </i>
              </h3>
              <p className='info'>
                Full Stack Developer
                <span>&bull;</span> <em className='date'>April 2020 - Present</em>
              </p>
              <p>
                As a Full Stack Developer at Shop-Apotheke, the online pharmacy market lead, I am working on the Front-End as well as the
                Backend. The languages I am working with are as follows: TypeScript, JavaScript, Java and PHP.
              </p>
            </div>
            <div>
              <h3>
                Prime Vision
                <i>
                  <em> / Delft</em>
                </i>
              </h3>
              <p className='info'>
                Full Stack Developer
                <span>&bull;</span> <em className='date'>August 2018 - March 2020</em>
              </p>
              <p>
                As a Full Stack Web developer at Prime Vision, I was developing Web Applications for PostNL, DHL and other customers. I was
                working on multiple projects in different tech stacks as well as ASP.NET, Node.js, C# and more. The work was really diverse
                there. I was working on really interesting projects from 3D Web Application to visualize robots to Web App for diabet
                patients.
              </p>
            </div>
            <div>
              <h3>
                LiveCoding
                <i>
                  <em> / Rotterdam</em>
                </i>
              </h3>
              <p className='info'>
                Founder - President
                <span>&bull;</span> <em className='date'>August 2019 - March 2020</em>
              </p>
              <p>
                When I saw the demand for web developers, I contacted my fellow developers around me to create a free, online and
                crowdsourced Web Development course. We did it, and finished our first class successfully. But then unexpected life changes
                unfortunately made me stop.
              </p>
            </div>
            <div>
              <h3>
                Restart Network
                <i>
                  <em> / Rotterdam</em>
                </i>
              </h3>
              <p className='info'>
                Lecturer
                <span>&bull;</span> <em className='date'>June 2018 - May 2019</em>
              </p>
              <p>
                Restart Network offered me an opportunity to help people like me with Live-Coding sessions and Master Classes. I was giving
                weekly lectures about Node.js, PHP and other Fundamentals of Web Development to help them pass their challenges.
              </p>
            </div>
            <div>
              <h3>
                Picnic Supermarket
                <i>
                  <em> / Amsterdam</em>
                </i>
              </h3>
              <p className='info'>
                Web Developer Intern
                <span>&bull;</span> <em className='date'>June 2018 - August 2018</em>
              </p>
              <p>
                I found an internship opportunity when I finished my bootcamp. While I was doing my Internship I got a good job offer and I
                accepted.
              </p>
            </div>
            <div>
              <h3>
                Tesbihane EU
                <i>
                  <em> / Amsterdam</em>
                </i>
              </h3>
              <p className='info'>
                Business Analysts & Web Developer
                <span>&bull;</span> <em className='date'>October 2016 - November 2017</em>
              </p>
              <p>
                One of the biggest jewelry companies in Turkey was trying to improve their European website. It was sort of a startup
                project. I worked as a mix of Web Developer and Business Analysts.
              </p>
            </div>
          </div>
        </div>
      </Slide>
    );
  }
  getEducation() {
    return (
      <Slide left duration={1300}>
        <div className='row education'>
          <div className='three columns header-col'>
            <h1>
              <span>Education</span>
            </h1>
          </div>

          <div className='nine columns main-col'>
            <div className='row item'>
              <div className='twelve columns'>
                <div>
                  <h3>Restart Network</h3>
                  <p className='info'>
                    Full Stack Web Developer Bootcamp
                    <span>&bull;</span>
                    <em className='date'>January 2018 - June 2018</em>
                  </p>
                  <p>
                    Creating Full Stack Web Applications with LAMP (Linux,Apache,MySQL,PHP) and MERN (MongoDB, Express.js, React, Node.js)
                    Stacks from scratch. Login-Registration systems, UI, Debugging and many different components of web development.
                  </p>
                </div>
                <div>
                  <h3>Gaziantep University</h3>
                  <p className='info'>
                    Bachelor of Arts - Theology
                    <span>&bull;</span>
                    <em className='date'>September 2011 - July 2016</em>
                  </p>
                  <p>
                    Learned during Philosophy and Sociology to understand human history. Found the opportunity to develop my thinking level
                    and gained critical thinking skills.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slide>
    );
  }

  getSkill(name) {
    return <span className='skill-name'>{name}</span>;
  }

  render() {
    return (
      <section id='resume'>
        {this.getWork()}

        <Slide left duration={1300}>
          <div className='row skill'>
            <div className='three columns header-col'>
              <h1>
                <span>Skills</span>
              </h1>
            </div>

            <div className='nine columns main-col'>
              <div>
                <h6 style={{ marginBottom: '15px' }}>Languages</h6>
                <div className='skill-list'>
                  {this.getSkill('JavaScript')}
                  {this.getSkill('TypeScript')}
                  {this.getSkill('Dart')}
                  {this.getSkill('PHP')}
                  {this.getSkill('C#')}
                  {this.getSkill('Java')}
                  {this.getSkill('HTML & CSS')}
                </div>
              </div>
              <div>
                <h6 style={{ margin: '15px 0' }}>Frameworks & Tools</h6>
                <div className='skill-list'>
                  {this.getSkill('Node.js')}
                  {this.getSkill('React')}
                  {this.getSkill('React Native')}
                  {this.getSkill('Flutter')}
                  {this.getSkill('ASP.NET')}
                  {this.getSkill('Maven')}
                  {this.getSkill('CodeIgniter')}
                  {this.getSkill('VueJS')}
                  {this.getSkill('Stencil')}
                  {this.getSkill('Bootstrap & jQuery')}
                  {this.getSkill('Redux & SSR')}
                  {this.getSkill('REST API’s')}
                  {this.getSkill('OOP & MVC')}
                  {this.getSkill('WebSocket & Socket.io')}
                  {this.getSkill('MySQL')}
                  {this.getSkill('MongoDB')}
                  {this.getSkill('Redis')}
                  {this.getSkill('Nginx & Apache')}
                  {this.getSkill('Docker')}
                  {this.getSkill('E2E Testing')}
                  {this.getSkill('Git & SVN ')}
                </div>
              </div>
              <div>
                <h6 style={{ margin: '15px 0' }}>Other Skills</h6>
                <div className='skill-list'>
                  {this.getSkill('Eye for Design')}
                  {this.getSkill('Eye for Design')}
                  {this.getSkill('Photoshop')}
                  {this.getSkill('Clear Communication')}
                  {this.getSkill('Agile Development & Scrum')}
                </div>
              </div>
            </div>
          </div>
        </Slide>
        {this.getEducation()}
      </section>
    );
  }
}

export default Resume;

import React, { Component } from 'react';
import Fade from 'react-reveal';
const social = [
  {
    name: 'linkedin',
    url: 'https://www.linkedin.com/in/tahaygun/',
    icon: (
      <svg width='50px' height='50px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 382 382'>
        <path
          fill='currentColor'
          d='M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889
   C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056
   H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806
   c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1
   s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73
   c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079
   c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426
   c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472
   L341.91,330.654L341.91,330.654z'
        />
      </svg>
    ),
  },
  {
    name: 'github',
    url: 'https://github.com/tahaygun',
    icon: (
      <svg width='50px' height='50px' viewBox='0 0 64 64' id='i-github' xmlns='http://www.w3.org/2000/svg'>
        <path
          strokeWidth='0'
          fill='currentColor'
          d='M32 0 C14 0 0 14 0 32 0 53 19 62 22 62 24 62 24 61 24 60 L24 55 C17 57 14 53 13 50 13 50 13 49 11 47 10 46 6 44 10 44 13 44 15 48 15 48 18 52 22 51 24 50 24 48 26 46 26 46 18 45 12 42 12 31 12 27 13 24 15 22 15 22 13 18 15 13 15 13 20 13 24 17 27 15 37 15 40 17 44 13 49 13 49 13 51 20 49 22 49 22 51 24 52 27 52 31 52 42 45 45 38 46 39 47 40 49 40 52 L40 60 C40 61 40 62 42 62 45 62 64 53 64 32 64 14 50 0 32 0 Z'
        />
      </svg>
    ),
  },
  {
    name: 'medium',
    url: 'https://medium.com/@tahaygun',
    icon: (
      <svg width='50px' height='50px' viewBox='0 0 15 15' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M0 1.5C0 0.671573 0.671573 0 1.5 0H13.5C14.3284 0 15 0.671573 15 1.5V13.5C15 14.3284 14.3284 15 13.5 15H1.5C0.671573 15 0 14.3284 0 13.5V1.5ZM4 5H3V4H4.5C4.6636 4 4.80885 4.07857 4.90006 4.20004L7.5001 7.66668L10.0934 4.20895C10.1841 4.08243 10.3324 4 10.5 4H12V5H11V10H12V11H9V10H10V6.00014L7.5001 9.33335L5 5.99988V10H6V11H3V10H4V5Z'
          fill='currentColor'
        />
      </svg>
    ),
  },
  {
    name: 'youtube',
    url: 'https://youtube.com/tahaygun',
    icon: (
      <svg version='1.1' fill='currentColor' xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 242.667 242.667'>
        <path
          fill='currentColor'
          d='M106.955,94.325l39.161,27.008l-39.161,27.008V94.325z M242.667,0v242.667H0V0H242.667z M190.25,101.494
	c0-16.378-13.277-29.655-29.655-29.655H82.072c-16.378,0-29.655,13.277-29.655,29.655v39.679c0,16.378,13.277,29.655,29.655,29.655
	h78.523c16.378,0,29.655-13.277,29.655-29.655V101.494z'
        />
      </svg>
    ),
  },
  {
    name: 'email',
    url: 'mailto:tahaygun@gmail.com',
    icon: (
      <svg version='1.1 ' fill='currentColor' xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 64 64'>
        <path
          id='Mail'
          d='M58.0034485,8H5.9965506c-3.3136795,0-5.9999995,2.6862001-5.9999995,6v36c0,3.3137016,2.6863203,6,5.9999995,6
	h52.006897c3.3137016,0,6-2.6862984,6-6V14C64.0034485,10.6862001,61.3171501,8,58.0034485,8z M62.0034485,49.1108017
	L43.084549,30.1919994l18.9188995-12.0555992V49.1108017z M5.9965506,10h52.006897c2.2056007,0,4,1.7943001,4,4v1.7664003
	L34.4677505,33.3134003c-1.4902,0.9492989-3.3935013,0.9199982-4.8495998-0.0703011L1.9965508,14.4694996V14
	C1.9965508,11.7943001,3.7910507,10,5.9965506,10z M1.9965508,16.8852005L21.182251,29.9251003L1.9965508,49.1108017V16.8852005z
	 M58.0034485,54H5.9965506c-1.6473999,0-3.0638998-1.0021019-3.6760998-2.4278984l20.5199013-20.5200024l5.6547985,3.843401
	c1.0859013,0.7383003,2.3418007,1.1083984,3.5995998,1.1083984c1.1953011,0,2.3925018-0.3339996,3.4463005-1.0048981
	l5.8423996-3.7230015l20.2961006,20.2961025C61.0673485,52.9978981,59.6508713,54,58.0034485,54z'
        />
      </svg>
    ),
  },
];
class Footer extends Component {
  render() {
    const networks = social.map(function (network) {
      return (
        <li key={network.name}>
          <a target='_blank' href={network.url} rel='noreferrer'>
            {network.icon}
          </a>
        </li>
      );
    });

    return (
      <footer id='footer'>
        <div className='row'>
          <Fade bottom>
            <div className='twelve columns'>
              <ul className='social-links'>{networks}</ul>
            </div>
          </Fade>

          <div id='go-top'>
            <a className='smoothscroll' title='Back to Top' href='#home'>
              <i className='icon-up-open'></i>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;

import React, { useCallback, useState } from 'react';
import Fade from 'react-reveal';
import ReactSimpleImageViewer from 'react-simple-image-viewer';

const photos = [
  {
    title: '4kCode',
    image: '4kcode1.jpg',
    description: 'Next.js applicaiton with 5 languages and rich SEO support.',
  },
  {
    title: 'Zaitoun',
    image: 'zaitoun1.jpg',
    description: 'Another internationalized website for a Charity in Rotterdam. Using the MERN stack.',
  },
  {
    title: 'Venture Cafe',
    image: 'venturecafe1.jpg',
    description: "A website for Venture Cafe, Rotterdam's famous network builder. Built with CodeIgniter/PHP.",
  },
  {
    title: 'Restart Market',
    image: 'restart1.jpg',
    description:
      'In this project, Restart Network asked us to create their own marketplace for students. The main feature is voice recognition, so admins can work with voice commands to take or add money.',
  },
  {
    title: 'Periodic Reminder',
    image: 'periodic-reminder.jpg',
    description:
      'I was always in a need for a smart reminder. When Google came up with Dart/Flutter combination to build native mobile applications I wanted to give it a shot and created this simple app in a week.',
  },
  {
    title: 'P2DM',
    image: 'p2dm3.jpg',
    description:
      'Predictive model-based decision support application for diabetes patients empowerment. I was responsible for the Shared Decision Making Application. It was a PWA built with React for doctors and patients.',
  },
];
const images = [
  'images/portfolio/4kcode1.jpg',
  'images/portfolio/zaitoun1.jpg',
  'images/portfolio/venturecafe1.jpg',
  'images/portfolio/restart1.jpg',
  'images/portfolio/periodic-reminder.jpg',
  'images/portfolio/p2dm3.jpg',
];
let id = 0;
const Portfolio = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const projects = photos.map((project, index) => {
    let projectImage = 'images/portfolio/' + project.image;

    return (
      <div key={id++} className='columns portfolio-item'>
        <div className='item-wrap'>
          <img style={{ cursor: 'pointer' }} alt={project.title} src={projectImage} onClick={() => openImageViewer(index)} />
          <div style={{ textAlign: 'center', fontWeight: 'bold', paddingTop: '15px', color: 'black' }}>{project.title}</div>
          <p>{project.description}</p>
        </div>
      </div>
    );
  });

  return (
    <section id='portfolio'>
      {isViewerOpen && (
        <ReactSimpleImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
      <Fade left duration={1000} distance='40px'>
        <div className='row'>
          <div className='twelve columns collapsed'>
            <h1>Check Out Some of My Works.</h1>

            <div id='portfolio-wrapper' className='bgrid-quarters s-bgrid-thirds cf'>
              {projects}
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default Portfolio;

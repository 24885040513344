import React, { Component } from 'react';
import Fade from 'react-reveal';

class About extends Component {
  render() {
    const profilepic = 'https://avatars2.githubusercontent.com/u/35219836?s=400&u=03c0e4352106f624080e55d43c1639ef894cb74f&v=4';

    return (
      <section id='about'>
        <Fade duration={1000}>
          <div className='row'>
            <div className='three columns'>
              <img className='profile-pic' src={profilepic} alt='Nordic Giant Profile Pic' />
            </div>
            <div className='nine columns main-col'>
              <h2>About Me</h2>

              <p>
                A developer who can enjoy while coding as playing a game or any other joyfull activity. Also, who thinks that coding is an
                art like painting. I am able to develop web and mobile applications with various tools and ship them to the production.
              </p>
              <div className='row'>
                <div className='columns contact-details'>
                  <h2>Contact Details</h2>
                  <p className='address'>
                    <span>Cologne / Bonn Area</span>
                    <br />
                    <span>Germany</span>
                    <br />
                    <a href='mailto:tahaygun@gmail.com'>tahaygun@gmail.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
